export default function MarkerColor(marker) {
        //If 30 seconds have passed, change the color of the marker
        const updatedData = new Date().getTime() - parseInt(marker.data.timestamp)*1000 > 30000;
    
        if(marker.data.color === "0000000") {
          if(updatedData){
            return "location_blue_gray";
          }
          else 
          return "0000000";
        }
         else if(marker.data.color === "0000001"){
          if(updatedData){
                return "location_green_gray";
            }
             else 
                return "0000001";
        } 
        else if(marker.data.color === "0000002") {
          if(updatedData){
                return "location_lightblue_gray";
            } 
            else 
                return "0000002";
        } 
        else if(marker.data.color === "0000003"){
          if(updatedData){
                return "location_orange_gray";
            } 
            else 
                return "0000003";
        } 
        else if(marker.data.color === "0000004") {
          if(updatedData){
                return "location_red_gray";
            } 
            else 
                return "0000004";
        } 
        else if(marker.data.color === "0000005" ){
          if(updatedData){
                return "location_yellow_gray";
            }
             else 
                return "0000005";
        } 
        else {
            if(updatedData){
                return "location_pink_gray";
            } 
            else 
                return "0000006";
            }
};
