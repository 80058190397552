export default function MarkerGroupColor(marker) {
        if(marker.data.color === "0000000") {
          return "#1834ec";
        }
        else if(marker.data.color === "0000001"){
              return "#38cc24";
        } 
        else if(marker.data.color === "0000002") {
              return "#60d4d4";
        } 
        else if(marker.data.color === "0000003"){
              return "#d8bc5c";
        } 
        else if(marker.data.color === "0000004") {
              return "#f80c4c";  
        } 
        else if(marker.data.color === "0000005" ){
              return "#f8e40c";
        } 
        else {  
              return "#d85cc4";
        }
};
