import React from 'react';
import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { ClearOutlined } from '@ant-design/icons'
import { Button, Select, message } from 'antd';
import "./ShowMarkers.css"
import MarkerIcon from '../Components/MarkerIcon';
import { UserContext } from '../Components/UserContext';

export default function ShowMarkers() {
const { loggedIn, setLoggedIn, userName } = useContext(UserContext);
const [devicesArray, setDevicesArray] = useState([]);

const [devicesImeiArray, setDevicesImeiArray] = useState([]);

// Here we will store all the groups that exists from the API request
const [groupsNames, setGroupsNames] = useState([]);

//*** */ TEMP HARD ENCODED GROUPS NAMES ARRAY****
const [groupsNames2, setGroupsNames2] = useState(["TunnelTest", "BNetTest", "UaeTest","MobileTest"]);
const [groupsForGuestNames, setGroupsForGuestNames2] = useState(["ARMONIC"]);
//Here we will store the chosen group, and then send it to the getImeiByGroupNameAndPassword function ;
const [chosenGroup, setChosenGroup] = useState("");

//Here we will save the password from the user ;
const [password, setPassword] = useState("");


const [activeMarkerIcon, setActiveMarkerIcon] = useState("");


//TESTTTTTTTTTTTTTTTTTTTTTTT--------------------------------------
const [showMarkers, setShowMarkers] = useState(true);
//-----------------------------------------------------------

//-----------------STEP 1-----------------
//GET request to get all the groups names that available to display in the Select option and to set it to the groupsNames array:
//This function called only once at the start to display the user all the avilable groups. we should use hear with useEffect with empty array as dependency


 //*********************************************************** */

    ///THIS WILL BE IN COMMENT TILL WE FIX THE ISSUE WITH THE SERVER
// const getGroupsNames = async () => {
//   console.log(`TEST`)
//   const requestGroupsNames = await axios.get(process.env.REACT_APP_GROUPS_NAMES)
//   .then((response) => response)
//   .then((data) => {
//    setGroupsNames((data.data));
//   //  message.success(`Login Success`,2);
//    })
//   .catch(error => {
//    message.error(`Failed to get data: ${error}`,5);
//    console.error(error);
//     })
//  };
// //Calling the getGroupsNames() only once!
//  useEffect(()=>{
//   getGroupsNames();
//  },[]);

//************************************************************************** */

//-----------------STEP 1 END-----------------


//  In the future we will change the GET request with USERNAME and PASSWORD....If EVERYTHING IS ALRIGHT WE WILL CONTINUE IN THE CODE...ELSE WE WILL REPEAT THE PROCCESS AND SHOW ERROR GIF


 //Here in the future we will get to this function the selected array from user with password
// const getImeiByGroupName = (chosenGroup,password) => {
//  const requestGroups =  groupsDevices?.map(async url => await axios.get(url)
//  .then((response) => response)
//  .then((data) => {
//   setDevicesImeiArray(JSON.parse(data.data.imei_list));
//   message.success(`Login Success`,2);
//   // console.log(data.data.imei_list)
//   })
//  .catch(error => {
//   message.error(`Failed to get data: ${error}`,5);
//   console.error(error);
//    })
//   ); 
// };



//-----------------STEP 2-----------------

// This is the selected group name from the Select option
const groupName = (value) => {
  // console.log(`selected ${value}`);
  setChosenGroup(value);//Here we set the chosen group name and send it to the varaible
 let passwordForGroup = prompt(`Insert password for ${value}:`);
 switch (passwordForGroup) {
  case null:
  case "":
  case " ":
  case undefined:
       {
    message.error(`Incorrect or invalid password`,5);
    console.error("Incorrect or invalid password");
    setChosenGroup(null);
    break;
    }
  default:{
    setPassword(passwordForGroup);
    // message.loading(`Sending data...`,1);
  }
}

};

//After we setting the chosen group and password we call the function getImeiByGroupName(chosenGroup,password) and starting the proccess
useEffect(() => {
if (chosenGroup !== '' && password !== '') {
  message.loading(`Sending data...`, 1);
  getImeiByGroupNameAndPassword(chosenGroup, password);
}
}, [chosenGroup, password]);

//-----------------STEP 2 END-----------------


//-----------------STEP 3-----------------

const getImeiByGroupNameAndPassword = async (chosenGroup,password) => {
  setShowMarkers(true);
  // console.log(`the chosen group is: ${chosenGroup} and the password is ${password}`)
  //const requestGroups = await axios.get(process.env.REACT_APP_REQUEST_GROUPS+`${chosenGroup}`)    
  const requestGroups = await axios.get(process.env.REACT_APP_REQUEST_GROUPS_OLD+`${chosenGroup}`)    
 .then((response) => response)
 .then((data) => {
  //  console.log(data)
  setDevicesImeiArray(JSON.parse(data.data.imei_list));
  message.success(`Login Success`,3);
  })
 .catch(error => {
  message.error(`Failed to get data: ${error}`,5);
  console.error(error);
   })
};

 //-----------------STEP 3 END-----------------

   
  //Here we run over the IMEI ARRAY and for each IMEI we use GET method to recive its own data
  //-----------------STEP 4-----------------
  useEffect(() => {
    const requests = devicesImeiArray.map(async deviceImei => await axios.get(process.env.REACT_APP_DEVICES_IMEIS+`${deviceImei}`)); 
    if(requests.length){
    setTimeout(() => {
      getData(requests);
    }, 2000);}
  }, [devicesArray , devicesImeiArray]);


  const getData = (requests) => {
    axios
      .all(requests)
      .then(responses => {
       setDevicesArray(responses);//Here we setting all the information for each device 
      //  console.log(devicesArray.length);
      })
      .catch(error => {
        console.error(error);
        message.error(`Failed to get data to devices array: ${error}`,5);
      });
  };

 //-----------------STEP 4 END-----------------
  
  const markerGroupColor = (marker) => {

    if(marker.data.color === "0000000") {
      return "blueGroup";
    }
    else if(marker.data.color === "0000001"){
        return "greenGroup";
    } 
    else if(marker.data.color === "0000002") {
        return "aquaGroup";
    } 
    else if(marker.data.color === "0000003"){
        return "desertGroup";
    } 
    else if(marker.data.color === "0000004") {
        return "redGroup";  
    } 
    else if(marker.data.color === "0000005" ){
        return "yellowGroup";
    } 
    else {  
        return "purpleGroup";
    }
  };

  const deleteInformation = () => {
    // console.log(`Delete information`)
    setChosenGroup("");
    setPassword("");
    setDevicesImeiArray([]);
    setDevicesArray([]);
    setShowMarkers(false);
  }

 return (
  <>
    <div style={{backgroundColor:"#D7D8D2"}}><br></br>
    <span style={{display:"table", margin: "0 auto",fontWeight: 'bold',color:"#2980B9"}}>Current Group : {chosenGroup}</span>
    <span style={{ fontWeight: 'bold',color:"#2980B9" }}> Choose your group : </span> &nbsp; 
    {/* Here we will show all the groups that the user can choose */}
    
    <Select id='SELECT GROUP'
      placeholder="Select Group"
      value={chosenGroup}
      onChange={groupName}
      style={{ width: 150,left:"10px" }} >
        {(userName === 'ARMONIC' ? groupsForGuestNames : groupsNames2)?.map((group, index) => {
            return <Select.Option value={group} key={index}>{group}</Select.Option>
        })}
    </Select>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {/* Here we will call the chosen group again to see if their is changes inside of him  , it will call getImeiByGroupName function again with chosengroup and password*/}
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <Button style={{ borderColor: "red", color: "red",right:"5px",position:"absolute"}} icon={<ClearOutlined style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}/>} onClick={ () => deleteInformation()} >Clear Data</Button>
        {/* O(n * log(n)) for Sorting and Mapping  the whole array */}
    <div style={{backgroundImage:"url(Home.png)"}}>
        {showMarkers && devicesArray?.sort((a, b) => a.data.color.localeCompare(b.data.color)).map((device, index) => (  
      <div key={index} className='card' style={{ width: "55%", margin: "0 auto", padding: "1rem" }}>    
        <div key={index}> 
          <div key={index} className='card-body'>
             {/* Show us if active or not */}
            <div key={index} className="switchPosition" style={{position:"absolute", right:"25px"}}><img alt='switch' src={`${MarkerIcon(device)}.png`}></img></div>
            <h6 className='card-text' style={{ fontWeight: 'bold' }}>IMEI: <span style={{ fontWeight: 'normal' }}>{device.config.url.substring(device.config.url.length-15)}</span> {MarkerIcon(device) === "activeMarker"? <span style={{position:"absolute",right:"50px",top:"36px"}}> Active </span> : <span style={{position:"absolute",right:"50px",top:"36px"}}> Not Active </span> }</h6>
            <h6 className='card-text' style={{ fontWeight: 'bold' }}>Driver Name: <span style={{ fontWeight: 'normal' }}>{device.data.driver_name}</span></h6>
            <h6 className='card-text' style={{ fontWeight: 'bold' }}>Lat: <span style={{ fontWeight: 'normal' }}>{device.data.lat}</span></h6>
            <h6 className='card-text' style={{ fontWeight: 'bold' }}>Lng: <span style={{ fontWeight: 'normal' }}>{device.data.lng}</span></h6>
            <h6 className='card-text' style={{ fontWeight: 'bold' }}>Color: <span style={{ fontWeight: 'normal' }}>{device.data.color}</span><span><img style={{position:"absolute",right:"5px"}} alt='groupColor' src={`${markerGroupColor(device)}.png`}></img> </span></h6>
            <h6 className='card-text' style={{ fontWeight: 'bold' }}>Date: <span style={{ fontWeight: 'normal' }}>{new Date(device.data.timestamp*1000).toLocaleString() }</span></h6> 
          </div>
        </div>
      </div>
        ))}
    </div>
    </div>
  </>
  )
};