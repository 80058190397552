import React from 'react';
import { createRoot } from 'react-dom/client';
import { Routes, Route, HashRouter, Navigate,Outlet } from 'react-router-dom';
import { UserProvider, UserContext } from './Components/UserContext';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import NavBar from './NavBar';
import Home from './screens/Home';
import Map from './screens/Map';
import ShowMarkers from './screens/ShowMarkers';
import SignIn from './screens/SignIn';
import Test from './screens/Test';
import MapBoxTerrin from './screens/MapBoxTerrin';
import Jene from './screens/Jene';

const rootElement = document.getElementById('root');

createRoot(rootElement).render(
    <HashRouter>
      <UserProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signIn" element={<SignIn />} />
          <Route element={<PrivateRouteWrapper />}>
            <Route path="/map" element={<Map />} />
            <Route path="/3dmap" element={<Test />} />
            <Route path="/showMarkers" element={<ShowMarkers />} />
            <Route path="/mapBoxTerrin" element={<MapBoxTerrin />} />
            <Route path="/jene" element={<Jene />} />
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </UserProvider>
    </HashRouter>
);

function PrivateRouteWrapper() {
  const { loggedIn } = React.useContext(UserContext);

  return loggedIn ? (
    <>
      <NavBar />
      <Outlet/>
    </>
  ) : (
    <Navigate to="/" replace />
  );
}
