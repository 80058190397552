const convertToCSV = (data) => {
    const keys = Object.keys(data[0]);
    const csvRows = data.map(row => 
      keys.map(key => {
        let cell = row[key];
        if (typeof cell === 'string') {
          cell = cell.replace(/"/g, '""'); // Escape double quotes
        }
        return `"${cell}"`; // Enclose in double quotes
      }).join(',')
    );
    return [keys.join(','), ...csvRows].join('\n');
  };
  
  export default function DownloadCsv(fileName, data) {
    const csvContent = convertToCSV(data);
    const csvData = new Blob([csvContent], { type: 'text/csv' });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement('a');
    link.href = csvURL;
    link.download = `${fileName}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };