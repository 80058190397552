import React, { useState, useEffect,useContext } from 'react';
import { MDBContainer } from 'mdb-react-ui-kit';
import axios from 'axios';
import { message,Button, Form, Input } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import "./SignIn.css"
import Timer from '../Components/Timer';
import { UserContext } from '../Components/UserContext';

export default function SignIn() {
  const navigate = useNavigate();
  const location = useLocation();
  //Check if the user log in from mobile or computer for faster loading
  const [isMobile, setIsMobile] = useState(false);

   useEffect(() => {
     const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
     setIsMobile(isMobileDevice);
   }, []);
 
  const backgroundImageUrl = isMobile ? "/HomePhone.png" : "/HomeLoadingGif.gif";

  const { setLoggedIn } = useContext(UserContext)

  const [password, setPassword] = useState("");

  const onFinish = async (value) => {
        // Update the password state
        setPassword(value.password);
        // Send a request to the server to validate the password
        const response = await axios.post(`${process.env.REACT_APP_SECONDARY_LOGIN}`, {
            "username": location.state,
            "specialPassword": password
        }).then((response) => {
          // Check the response
        if (response.request.status === 200) {
          message.success("Login successful",1);
          setLoggedIn(true);
          navigate("/map");
          }
        }, (error) => {
          if (error.response && error.response.status === 401) {
            message.error(`Unauthorized: Invalid credentials`, 5);
            
        } else {
            message.error(`Validation failed`, 5);
        }
          console.clear();
          console.log(error.response.status);
        });
    };

  //If the user not writing anything indise...
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    setPassword("");
  };
  
  const backToLogin = () => {
    navigate("/");
  };

  return (
    // <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundImage: "url(/Home.png)", backgroundSize: 'cover', backgroundPosition: 'center' }}>
    //<div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundImage: "url(/HomeLoadingGif.gif)", backgroundSize: 'cover', backgroundPosition: 'center' }}>
  <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundImage: `url(${backgroundImageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}> 

    <div className='seonderyDiv'>
      <MDBContainer className="p-3 my-5 d-flex flex-column align-items-center w-50">
        <img alt='logo' width={"100%"} height={"100%"} src={'mobileGroupWhite.png'} position={'absolute'} />
        <br/>
        <div className="text-center" >
          <Timer/>
        </div>
        <br/>
  
        <Form
              name="basic"
              className="text-center"
              labelCol={{ span: 24 }} 
              wrapperCol={{ span: 24 }} 
              style={{ maxWidth: 500 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label={<label className='formLabel'>Password</label>}
                name="password"
                labelAlign="left"
                labelCol={{ span: 24 }} 
                wrapperCol={{ span: 24 }} 
                rules={[
                  {
                    required: true,
                    message: 'Please insert your password!',
                  },
                ]}
              >
                <Input  type='password' maxLength={16} style={{textAlign:"center"}} autoFocus onChange={(e) => setPassword(e.target.value)}/>
              </Form.Item>
  
              <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'center' }}>
                <Button type="primary" htmlType="submit" className='loginButton'>Login</Button>
              </Form.Item>
  
            </Form>
        <br/>
        <button type="button" className='btn btn-info' onClick={backToLogin}>Change User Name</button>
      </MDBContainer>
    </div>
    </div>
  );
};
