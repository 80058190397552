import React from 'react';
import "./DeviceStatus.css"

export default function DevicesStatus({devicesArray}) {

    const overView = () =>{
        let activeDevices = 0;
        let notActiveDevices = 0;
       for (let i = 0; i < devicesArray.length; i++) {
         const updatedData = new Date().getTime() - parseInt(devicesArray[i].data.timestamp)*1000 > 30000;
        if(updatedData){
          notActiveDevices++;
        }
        else{
          activeDevices++;
        }
       }
        const devicesInfo = {
          activeDevices: activeDevices,
          notActiveDevices: notActiveDevices
        };
        return devicesInfo;
      };

    
      return (
        <div className='deviceStatusFrame'>
          <p style={{ color: '#00C853', fontWeight: 'bold', margin: '5px 0', }}>Active: {overView().activeDevices}</p>
          <p style={{ color: '#FF5252', fontWeight: 'bold', margin: '5px 0', }}>Not Active: {overView().notActiveDevices}</p>
          <p style={{ color: '#4096FF', fontWeight: 'bold', margin: '5px 0', }}>Total: {devicesArray.length}</p>
        </div>
      );
};
