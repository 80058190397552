export default function RuleStatus(rule) {
    // Check if rule is null or undefined
     if (rule === null || rule === undefined) {
         return;
     }
     // Update the condition to match the actual object structure
     if (rule?.fotaUrl != null && rule?.enabled === "true") {
         return "activeMarker";
     } else {
         return "notActiveMarker";
     }
 };
 