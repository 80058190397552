import React, { useState , useEffect, useRef, useContext } from 'react';
import { MDBContainer } from 'mdb-react-ui-kit';
import { SendOutlined  } from '@ant-design/icons'
import { message,Button, Form, Input } from 'antd';
import axios from 'axios';
import './Home.css'
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../Components/UserContext';

export default function Home() {
  const navigate = useNavigate();
  const { setUserName } = useContext(UserContext);
  //Check if the user log in from mobile or computer for faster loading
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
    setIsMobile(isMobileDevice);
  }, []);

  const backgroundImageUrl = isMobile ? "/HomePhone.png" : "/HomeLoadingGif.gif";
  // const [userName, setUserName] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const lastActiveTimeRef = useRef(Date.now());

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        // User put computer to sleep, reset last active time
        lastActiveTimeRef.current = Date.now();// we take the last time it happaned
      } else {
        // User woke computer up, check for inactivity
        const inactiveTime = Date.now() - lastActiveTimeRef.current;//we calculating the duration it went to sleep...
        if (inactiveTime >= 60000) {//if the sleep duration was above 15 minutes we disconnecting the user
          //  deleteInformation();
           message.error(<p style={{fontWeight: "bold"}}>Disconnected: The system lost connection to the server...please reconnect.</p>,15);
          // User inactive for 15 min, log out the user
          setIsLoggedIn(false);
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isLoggedIn]);

//  console.log(document.visibilityState)//help us to see if the app is on focus or not
//Send the server the userName in GET Method if the user exist the server send status 200 OK and then we can navigate to next page ...ELSE the server will return status 404 and we will send alert to the user that validation failed!
  const onFinish = async (value) => {
    const userName = value.username;
    setUserName(userName);
    //Check for valid text --- STEP 1 ---
    if (userName == null || userName === undefined || userName.trim() === '' || userName.includes(" ")) {
      message.error('Invalid user name', 4);
      setUserName("");
      return;
    }
    // --- STEP 2 ---
    // Here we will send the server the userName 
    const checkIfUserExist = await axios.get(`${process.env.REACT_APP_FIRST_LOGIN}${userName}`)
      .then((response) => response)
      .then((data) => {
       if(data.status === 200){ //Send the server the userName in GET Method if the user exist the server send status 200 OK and then we can navigate to next page
        message.info(`A verification password has been send to your email account`,8);
        navigate("./SignIn",{state: userName});
        }
     }).catch((error) => { 
       console.clear();
       message.error(`Validation failed`,5);
       console.log(error.response.status); 
        // console.log(error.message);
      })
  };

  //If the user not writing anything indise...
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    setUserName("");
  };

  return (
    <>
      {/* <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundImage: "url(/Home.png)", backgroundSize: 'cover', backgroundPosition: 'center' }}>  */}
      {/* <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundImage: "url(/HomeLoadingGif.gif)", backgroundSize: 'cover', backgroundPosition: 'center' }}>  */}
      <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundImage: `url(${backgroundImageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}> 

        <div className='seconderyDiv'>
          <MDBContainer className="p-3 my-5 d-flex flex-column align-items-center w-50">
            <img alt='logo' width={"100%"} height={"100%"} src={'mobileGroupWhite.png'} position={'absolute'} />
            <br /><br />
  
            <Form
              name="basic"
              className="text-center"
              labelCol={{ span: 24 }} 
              wrapperCol={{ span: 24 }} 
              style={{ maxWidth: 500 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label={<label className='formLabel'>User Name</label>}
                name="username"
                labelAlign='right'
                labelCol={{ span: 24 }} 
                wrapperCol={{ span: 24 }} 
                rules={[
                  {
                    required: true,
                    message: 'Please insert your User Name!',
                  },
                ]}
              >
                <Input type='text' maxLength={20} style={{textAlign:"center"}} autoFocus/>
              </Form.Item>
  
              <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'center' }}>
                <Button type="primary" htmlType="submit" className='firstLoginButton'>Send Me Password <SendOutlined className='sendOutlinedIconPosition'/></Button>
              </Form.Item>
  
            </Form>
          </MDBContainer>
        </div>
      </div>
    </>
  );
};

