import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Home from '../screens/Home';

const Timer = () => {
  const [timer, setTimer] = useState(300);
  const navigate = useNavigate();

  // Format the timer value as minutes and seconds
  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;

  useEffect(() => {
    // Decrease the timer by 1 every second
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    // Clean up the interval when the component unmounts or timer reaches 0
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Check if the timer reaches 0
    if (timer === 0) {
      // Navigate to Home.js
      navigate('/');
    }
  }, [timer, navigate]);

  return <h1 className='timer' style={{color:"white"}}>{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}</h1>;
};

export default Timer;
