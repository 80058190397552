import React, { useState, useEffect } from 'react';
import { Form, Input, Checkbox, Button, message, Modal, Select, Row, Col } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import axios from 'axios';
import "./CreateFotaRule.css";

export default function CreateFotaRule() {
  const [form] = Form.useForm();
  const [data, setData] = useState({
    description: "",
    model: "ANY",
    brand: "ANY",
    baseband: "ANY",
    enabled: "false",
    sourceFingerprints: "ANY",
    imeiList: "",
    destinationFingerprints: "ANY",
    url: ""
  });
  const [managerNames, setManagersNames] = useState([]);
  const [tempImeis, setTempImeis] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const { Option } = Select;
  const [modelsNames, setModelsNames] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);

  useEffect(() => {
    getNames();
  }, []);

  const getNames = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_MANAGERS_NAMES}`);
      const responseModelsNames = await axios.get(`${process.env.REACT_APP_MODELS_NAMES}`);
      setModelsNames(responseModelsNames.data.data);
      setManagersNames(response.data.names);
    } catch (error) {
      console.error('Error fetching manager names:', error);
    }
  };

  const handleSelectModelChange = async (value) => {
    setSelectedModel(value[0]);
  };

  const handleSelectChange = async (value) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SEND_MANAGER_PASSWORD}${value}`);
      if (response.status === 200) {
        setSelectedOption(value);
        message.info(`A verification password has been sent to ${value} email`, 8);
      }
    } catch (error) {
      message.error("Please try again later.");
      setSelectedOption(null);
      console.clear();
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordCancel = () => {
    setPassword('');
    setSelectedOption(null);
    setIsModalVisible(false);
  };

  const handlePasswordOk = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_VALIDATE_PASSWORD}`, {
        username: selectedOption,
        specialPassword: password
      });
      if (response.status === 200) {
        setData((prevData) => ({ ...prevData, imeiList: tempImeis }));
        message.success("Password correct, 'ANY' accepted.");
        handleSubmit(form.getFieldsValue());
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        message.error("Incorrect password. 'ANY' not accepted.");
      } else {
        message.error("An error occurred. Please try again later.");
      }
      setData((prevData) => ({ ...prevData, imeiList: data.imeiList }));
      console.clear();
    } finally {
      setSelectedOption(null);
      setPassword('');
      setIsModalVisible(false);
    }
  };

  const handleSave = () => {
    const imeis = data.imeiList;
    if (imeis.startsWith(',')) {
      message.error('IMEIs cannot start with a comma.');
      return;
    }
    if (imeis.includes(',,')) {
      message.error('IMEIs cannot contain consecutive commas.');
      return;
    }
    if (imeis.trim() === 'ANY') {
      setTempImeis(imeis);
      setIsModalVisible(true);
      return;
    }
    const validImeis = imeis.match(/^[ANY0-9, ]*$/);
    if (!validImeis) {
      message.error('IMEIs can only contain the word "ANY", numbers, commas, and spaces.');
      return;
    }
    if (imeis.includes('ANY') && imeis.trim() !== 'ANY') {
      message.error('IMEIs cannot contain a mix of "ANY" and other values.');
      return;
    }
    handleSubmit(form.getFieldsValue());
  };

  const handleSubmit = async (values) => {
    // Convert boolean enabled to string and handle empty fields
    const processedValues = {
      description: values.description || "",
      model: values.model[0] || "ANY",
      brand: values.brand || "ANY",
      baseband: values.baseband || "ANY",
      enabled: values.enabled ? "true" : "false",
      sourceFingerprints: values.sourceFingerprints || "ANY",
      imeiList: values.imeiList || "",
      destinationFingerprints: values.destinationFingerprints || "ANY",
      url: values.url || ""
    };
    
    setData(processedValues);
    try {
      const response = await axios.post(`${process.env.REACT_APP_ADD_RULE}`, processedValues);
      if (response.status === 200 || response.status === 201) {
        message.success('The Rule has been created successfully');
        form.resetFields(); // Reset the form on success
        setData({
          description: "",
          model: "ANY",
          brand: "ANY",
          baseband: "ANY",
          enabled: "false",
          sourceFingerprints: "ANY",
          imeiList: "",
          destinationFingerprints: "ANY",
          url: ""
        });
      }
    } catch (error) {
      if (error.response.status === 400){
        message.error('There was an error submitting the Rule!');
        console.clear();
      }
      if (error.response.status === 409){
        message.error('A Rule with this description already exists!',5);
        console.clear();
      }
    }
  };

  return (
    <div className='firstDivStyle'>
      <Form
        form={form}
        name="createFotaRule"
        onFinish={handleSave}
        layout="vertical"
        onValuesChange={(changedValues) => {
          setData((prevData) => ({ ...prevData, ...changedValues }));
        }}
      >
        <div className='formStyle'>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                style={{ fontWeight: 'bold' }}
                label="Description"
                name="description"
                rules={[{ required: true, message: 'Please insert the description!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
            
            <Form.Item
                style={{ fontWeight: 'bold' }}
                label="Model"
                name="model"
                rules={[{ required: true, message: 'Please insert the model!' }]}
              >
                <Select 
                  maxCount={1}
                  mode="tags"
                  style={{ width: '100%' }}
                  placeholder="Select or input your model"
                  onChange={handleSelectModelChange}
                  tokenSeparators={[',']}
                  value={selectedModel}
                >
                  {modelsNames.map((model, index) => (
                    <Option key={index} value={model}>
                      {model}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                style={{ fontWeight: 'bold' }}
                label="Brand"
                name="brand"
                rules={[{ required: false, message: 'Please input the brand!' }]}
              >
                <Input placeholder='ANY'/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                style={{ fontWeight: 'bold' }}
                label="Baseband"
                name="baseband"
                rules={[{ required: false, message: 'Please input the baseband!' }]}
              >
                <Input placeholder='ANY'/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Enabled"
                style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
              >
                <Form.Item name="enabled" valuePropName="checked" noStyle>
                  <Checkbox />
                </Form.Item>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                style={{ fontWeight: 'bold' }}
                label="Source Fingerprints"
                name="sourceFingerprints"
                rules={[{ required: false, message: 'Please input the source fingerprints!' }]}
              >
                <Input placeholder='ANY'/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                style={{ fontWeight: 'bold' }}
                label="IMEI List"
                name="imeiList"
                rules={[{ required: false, message: 'Please input the IMEI list!' }]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                style={{ fontWeight: 'bold' }}
                label="Destination Fingerprints"
                name="destinationFingerprints"
                rules={[{ required: false, message: 'Please input the destination fingerprints!' }]}
              >
                <Input placeholder='ANY'/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                style={{ fontWeight: 'bold' }}
                label="URL ☍"
                name="url"
                rules={[{ required: true, message: 'Please input the URL!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
            <Row justify="center">
                <Col>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ backgroundColor: "#2ECC71" }}>
                      Save<SendOutlined />
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Form>

      <Modal
        title="Enter Password for the ANY option!"
        open={isModalVisible}
        onOk={handlePasswordOk}
        onCancel={handlePasswordCancel}
        onClose={handlePasswordCancel}
      >
        <Select
          style={{ width: '100%', marginBottom: '16px' }}
          placeholder="Select who should receive the email"
          onChange={handleSelectChange}
          value={selectedOption}
        > 
          {managerNames.length > 0 && managerNames.map(name => (
            <Option key={name} value={name}>{name}</Option>
          ))}
        </Select>

        <Input.Password
          value={password}
          onChange={handlePasswordChange}
          placeholder="Enter password"
        />
      </Modal>
    </div>
  );
}
