import React from 'react';
import { Button, Form, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import "./SearchByImei.css";

export default function SearchByImei({ onFinish, onFinishFailed }) {
  return (
    <div className='inputFormPosition'>
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600, width: '100%' }}
      initialValues={{ remember: true }}
      autoComplete="off"
      clearOnDestroy={true}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Insert IMEI"
        name="requiredImei"
        rules={[{ required: true, message: 'Please insert your IMEI' }]}
      >
        <div style={{ display: 'flex' }}>
          <Input style={{ flex: 1 }} autoFocus={true} />
          <Button type="primary" htmlType="submit" style={{ marginLeft: '8px' }}>Find<SearchOutlined /></Button>
        </div>
      </Form.Item>
    </Form>
  </div>
  )
};
