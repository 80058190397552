import React, { useState } from 'react';
import { Line } from '@ant-design/charts';
import { Button, Form, Input, message } from 'antd';
import { SearchOutlined, ClearOutlined } from '@ant-design/icons';
import axios from 'axios';
import "./Statistics.css"

export default function Statistics() {
    const [form] = Form.useForm();
    const [chosenImei, setChosenImei] = useState('');
    const [data, setData] = useState([]);
    const config = {
        data,
        xField: 'DateTime',
        yField: 'CallCount',
        point: {
            size: 5,
            shape: 'circle',
        },
    };

    const getData = async (value) => {
        setChosenImei(value.requiredImei);
        try {
            const response = await axios.get(`${process.env.REACT_APP_UNIT_LAST_HISTORY + value.requiredImei}`);
            const responseData = response.data;
    
            if (responseData.length > 0) {
                // Group data by date and hour, and count occurrences
                const dateCounts = responseData.reduce((acc, item) => {
                    const date = new Date(item.last_check);
                    const DateTime = `${date.toDateString()} ${date.getUTCHours()}:00`; // Group by date and hour, only include up to hour
                    if (!acc[DateTime]) {
                        acc[DateTime] = 0;
                    }
                    acc[DateTime]++;
                    return acc;
                }, {});
    
                // Format data for the chart and sort by DateTime
                const formattedData = Object.entries(dateCounts).map(([DateTime, count]) => ({
                    DateTime,  // Keep the cleaned-up DateTime string
                    CallCount: count,
                })).sort((a, b) => new Date(a.DateTime) - new Date(b.DateTime)); // Sort by DateTime in ascending order
    
                setData(formattedData);
            } else {
                message.error("No data found!");
            }
        } catch (error) {
            deleteInformation();
            message.error("No data found!");
        }
    };
    
      const onFinishFailed = () => {};

      const deleteInformation = () => {
        console.clear();
        form.resetFields();
        setChosenImei('');
        setData([]);
      };

  return (
    <div>
        <div className='inputFormPosition'>
            <Form
                form={form}
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600, width: '100%' }}
                initialValues={{ remember: true }}
                autoComplete="off"
                clearOnDestroy={true}
                onFinish={getData}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="Insert IMEI"
                    name="requiredImei"
                    rules={[{ required: true, message: 'Please insert your IMEI' }]}
                >
                <div style={{ display: 'flex' }}>
                    <Input style={{ flex: 1 }} autoFocus={true} />
                    <Button type="primary" htmlType="submit" style={{ marginLeft: '8px' }}>Find<SearchOutlined /></Button>
                    <Button style={{ borderColor: "#E74C3C", backgroundColor:"#E74C3C", color: "white", right:"-5px"}} onClick={ () => deleteInformation()} >Clear<ClearOutlined/></Button>
                </div>
                </Form.Item>
            </Form>
        </div>
        {data.length > 0 && (
            <div>
                <Line
                    title={`Data for IMEI : ${chosenImei}  (Last 500 readings of the device)`} 
                    {...config} 
                    animate={true} 
                    colorField={"#009688"} 
                    axis={true}
                />
            </div>
        )}
    </div>
  )
};

