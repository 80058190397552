export default function MarkerIcon(marker){
 //If 30 seconds have passed, change the color of the marker
 const updatedData = new Date().getTime() - parseInt(marker.data.timestamp)*1000 > 30000;

 if(marker.data.color === "0000000") {
   if(updatedData){
     return "notActiveMarker";
   }
   else 
   return "activeMarker";
 }
  else if(marker.data.color === "0000001"){
   if(updatedData){
         return "notActiveMarker";
     }
      else 
         return "activeMarker";
 } 
 else if(marker.data.color === "0000002") {
   if(updatedData){
         return "notActiveMarker";
     } 
     else 
         return "activeMarker";
 } 
 else if(marker.data.color === "0000003"){
   if(updatedData){
         return "notActiveMarker";
     } 
     else 
         return "activeMarker";
 } 
 else if(marker.data.color === "0000004") {
   if(updatedData){
         return "notActiveMarker";
     } 
     else 
         return "activeMarker";
 } 
 else if(marker.data.color === "0000005" ){
   if(updatedData){
         return "notActiveMarker";
     }
      else 
         return "activeMarker";
 } 
 else {
     if(updatedData){
         return "notActiveMarker";
     } 
     else 
         return "activeMarker";
     }
};
