import React, { useState, useEffect } from 'react';
import { Card, Input, Checkbox, Button, message, Modal, Select } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import RuleStatus from './RuleStatus';
import "./InfoCard.css";

export default function InfoCard({ data, fetchData, imei, getModelsNames }) {
  const [editData, setEditData] = useState({
    ...data,
    enabled: data?.enabled === "true",
  });
  const [isModalVisibleDeleteRule, setIsModalVisibleDeleteRule] = useState(false);
  const [managerNames, setManagersNames] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [tempImeis, setTempImeis] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const currentDate = new Date().toLocaleString();
  const { Option } = Select;

  useEffect(() => {
    setEditData({
      ...data,
      enabled: data?.enabled === "true",
    });
  }, [data]);
 
  const getNames = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_MANAGERS_NAMES}`);
      setManagersNames(response.data.names);
    } catch (error) {
      console.error('Error fetching manager names:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setEditData((prevData) => ({ ...prevData, enabled: checked }));
  };

  const handleImeisChange = (e) => {
    const { value } = e.target;
    setEditData((prevData) => ({ ...prevData, imeis: value }));
  };

  const handleSelectChange = async (value) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SEND_MANAGER_PASSWORD}${value}`);
      if (response.status === 200) {
        setSelectedOption(value);
        message.info(`A verification password has been sent to ${value} email `, 8);
      }
    } catch (error) {
      message.error("Please try again later.");
      setSelectedOption(null);
      console.clear();
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordOk = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_VALIDATE_PASSWORD}`, {
        "username": selectedOption,
        "specialPassword": password
      });
      if (response.status === 200) {
        setEditData((prevData) => ({ ...prevData, imeis: tempImeis }));
        message.success("Password correct, 'ANY' accepted.");
        saveData();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setEditData((prevData) => ({ ...prevData, imeis: data.imeis }));
        message.error("Incorrect password. 'ANY' not accepted.");
        console.clear();
      } else {
        message.error("An error occurred. Please try again later.");
        console.clear();
      }
    } finally {
      setSelectedOption(null);
      setPassword('');
      setIsModalVisible(false);
    }
  };

  const handlePasswordOkDeleteRule = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_VALIDATE_PASSWORD}`, {
        "username": selectedOption,
        "specialPassword": password
      });
      if (response.status === 200) {
        message.success("Password correct, rule will be deleted.");
        deleteRule(data._id, data.imeis);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        message.error("Incorrect password. Rule not deleted.");
        console.clear();
      } else {
        message.error("An error occurred. Please try again later.");
        console.clear();
      }
    } finally {
      setSelectedOption(null);
      setPassword('');
      setIsModalVisibleDeleteRule(false);
    }
  };

  const handlePasswordCancel = () => {
    setPassword('');
    setSelectedOption(null);
    setIsModalVisible(false);
    setIsModalVisibleDeleteRule(false);
  };

  const handleSave = async () => {
    getNames();
    const imeis = editData.imeis;
    if (imeis.startsWith(',')) {
      message.error('IMEIs cannot start with a comma.');
      return;
    }
    if (imeis.includes(',,')) {
      message.error('IMEIs cannot contain consecutive commas.');
      return;
    }
    if (imeis.trim() === 'ANY') {
      setTempImeis(imeis);
      setIsModalVisible(true);
      return;
    }
    const validImeis = imeis.match(/^[ANY0-9,\n\r ]*$/);
    if (!validImeis) {
      message.error('IMEIs can only contain the word "ANY", numbers, commas, and spaces.');
      return;
    }
    if (imeis.includes('ANY') && imeis.trim() !== 'ANY') {
      message.error('IMEIs cannot contain a mix of "ANY" and other values.');
      return;
    }

    saveData();
  };

  const saveData = async () => {
    const dateRegex = /\(Edited on: .*\)$/;
    const updatedDate = ` (Edited on: ${currentDate})`;
    let updatedDescription = editData.description.replace(dateRegex, '').trim();
    updatedDescription += updatedDate;

    const updatedData = { 
      ...editData, 
      description: updatedDescription,
      enabled: editData.enabled ? "true" : "false"
    };

    try {
      setEditData(updatedData);
      const response = await axios.put(`${process.env.REACT_APP_UPDATE_FOTA_RULE}${updatedData._id}`,{
        "description": updatedData.description,
        "destinationFingerprints": updatedData.destinationFingerprints,
        "enabled": updatedData.enabled,
        "fotaUrl": updatedData.fotaUrl,
        "imeis": updatedData.imeis,
        "model": updatedData.model,
        "sourceFingerprints": updatedData.sourceFingerprints
      });
      if (response.status === 200){
        setIsEditing(false);
        message.success("The rule has been updated successfully");
        fetchData(imei);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        message.error("Error updating data, ID not found!");
        console.clear();
      }
      if (error.response && error.response.status === 404){
        message.error("Error updating data!");
        console.clear();
      }
    }
  };

  const deleteButtonHandle = () => {
    getNames();
    setIsModalVisibleDeleteRule(true);
  };

  const deleteRule = async (id, imei) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_DELETE_FOTA_RULE}${id}`);
      if (response.status === 200){
        message.success("The rule has been deleted successfully");
        getModelsNames();
        fetchData(imei);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        message.error("Error deleting rule, ID not found!");
        console.clear();
      }
      if (error.response && error.response.status === 404){
        message.error("Error deleting data!");
        console.clear();
      }
      if (error.response && error.response.status === 409){
        message.error("Description already exist! please change the description");
        console.clear();
      }
    }
  }; 

  if (!data) {
    return null;
  }

  return (
    <>
      <Card style={{ borderColor: "#2980B9", margin: 5 }} key={data?._id}>
        {isEditing ? 
        (null) : (
      <div>
        <div className='ruleStatusPosition'>
          <div>
            {RuleStatus(data) === "activeMarker" ? (<span style={{ fontWeight: "bold" }}> Active </span>) : (<span style={{ fontWeight: "bold" }}> Not Active </span>)}
          </div>
          <img alt='ruleStatus' src={`${RuleStatus(data)}.png`} style={{ marginLeft: "10px" }} />
        </div>
        <div className='deleteButtonPosition'>
          <Button style={{ borderColor: "red" }} onClick={deleteButtonHandle}>
            <DeleteOutlined style={{ color: "red" }} />
          </Button>
        </div>
      </div>
        )}
        <h6 style={{ fontWeight: "bold" }}>
          Description:
          {isEditing ? (
            <Input name="description" value={editData.description} onChange={handleChange} />
          ) : (
            <span style={{ fontWeight: "lighter" }}> {data?.description}</span>
          )}
        </h6>
        <h6 style={{ fontWeight: "bold" }}>
          Enabled:&nbsp;
          {isEditing ? (
            <Checkbox name="enabled" checked={editData?.enabled} onChange={handleCheckboxChange} />
          ) : (
            <span style={{ fontWeight: "lighter" }}> {data?.enabled}</span>
          )}
        </h6>
        <h6 style={{ fontWeight: "bold" }}>
          Model:
          {isEditing ? (
            <Input name="model" value={editData.model} onChange={handleChange} />
          ) : (
            <span style={{ fontWeight: "lighter" }}> {data?.model}</span>
          )}
        </h6>
        <h6 style={{ fontWeight: "bold" }}>
          Fota Url:
          {isEditing ? (
            <Input name="fotaUrl" value={editData.fotaUrl} onChange={handleChange} />
          ) : (
            <span style={{ fontWeight: "lighter" }}> {data?.fotaUrl}</span>
          )}
        </h6>
        <h6 style={{ fontWeight: "bold" }}>
          Source Fingerprints:
          {isEditing ? (
            <Input name="sourceFingerprints" value={editData.sourceFingerprints} onChange={handleChange} />
          ) : (
            <span style={{ fontWeight: "lighter" }}> {data?.sourceFingerprints}</span>
          )}
        </h6>
        <h6 style={{ fontWeight: "bold" }}>
          Imeis:
          {isEditing ? (
            <Input.TextArea name="imeis" value={editData.imeis} onChange={handleImeisChange} />
          ) : (
            <span style={{ display: "block", wordWrap: "break-word", whiteSpace: "normal", fontWeight: "lighter" }}> {data?.imeis}</span>
          )}
        </h6>
        <h6 style={{ fontWeight: "bold" }}>
          Destination Fingerprints:
          {isEditing ? (
            <Input name="destinationFingerprints" value={editData.destinationFingerprints} onChange={handleChange} />
          ) : (
            <span style={{ fontWeight: "lighter" }}> {data?.destinationFingerprints}</span>
          )}
        </h6>
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          {isEditing ? (
            <Button type="primary" onClick={handleSave}>Save</Button>
          ) : (
            <Button type="primary" onClick={() => setIsEditing(true)}>Edit</Button>
          )}
        </div>
      </Card>

      <Modal
        title="Enter Password for the ANY option!"
        open={isModalVisible}
        onOk={handlePasswordOk}
        onCancel={handlePasswordCancel}
        onClose={handlePasswordCancel}
      >
        <Select
          style={{ width: '100%', marginBottom: '16px' }}
          placeholder="Select who should receive the email"
          onChange={handleSelectChange}
          value={selectedOption}
        > 
        {managerNames.length > 0 && managerNames?.map(name => (
            <Option key={name} value={name}>{name}</Option>
            ))}
        </Select>

        <Input.Password
          value={password}
          onChange={handlePasswordChange}
          placeholder="Enter password"
        />
      </Modal>
      
      <Modal
        title="Enter Password to delete this rule!"
        open={isModalVisibleDeleteRule}
        onOk={handlePasswordOkDeleteRule}
        onCancel={handlePasswordCancel}
        onClose={handlePasswordCancel}
      >
        <Select
          style={{ width: '100%', marginBottom: '16px' }}
          placeholder="Select who should receive the email"
          onChange={handleSelectChange}
          value={selectedOption}
        > 
        {managerNames.length > 0 && managerNames?.map(name => (
            <Option key={name} value={name}>{name}</Option>
            ))}
        </Select>

        <Input.Password
          value={password}
          onChange={handlePasswordChange}
          placeholder="Enter password"
        />
      </Modal>
    </>
  );
}
