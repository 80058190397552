import React from 'react'
import { useLoadScript } from '@react-google-maps/api'
import ShowMap from '../Components/ShowMap';

export default function Map() {
  const {isLoaded} = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

//If the map still loads or it will show us this loading GIF:
if(!isLoaded) return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundColor: '#ECF0F1'}}><img src='/loadingMap.gif' alt='loading gif' ></img></div>
//If the map loads succesfully it will call the function bellow that show us the map: <ShowMap/> 
  return <ShowMap/>;
}


   